import styled from 'styled-components';

export const Container = styled.div`
  max-width: 992px;
  width: 992px;
  margin: 0 0 0 50%;
  transform: translateX(-50%);
  align-self: flex-start;
  padding: 52px 0;

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    max-width: 991px;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
    max-width: 767px;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
    width: 355px;
    max-width: 355px;
  }
`;
