import React from 'react';
import PropTypes from 'prop-types';

import { Content, Circle } from './styles';

const Bubble = ({ image, name }) => {
  return (
    <Content>
      <Circle>
        <img src={image} alt={name} aria-label={`${name} Logo`} />
      </Circle>
      <h6>{name}</h6>
    </Content>
  );
};

Bubble.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Bubble;
