import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const HeroImage = ({ children }) => <Container>{children}</Container>;

HeroImage.propTypes = {
  children: PropTypes.node.isRequired,
};
export default HeroImage;
