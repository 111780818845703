import React from 'react';

import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import PropTypes from 'prop-types';

import Layout from 'core/layout';
import MetaTags from 'core/meta-tags/index';
import Image from 'core/image';
import Bubbles from 'atomic-design/molecules/bubbles';
import HeroImage from 'core/image/hero-image';
import WorkRichText from 'atomic-design/organisms/work-rich-text';
import NavigationCards from 'atomic-design/molecules/navigation-cards';
import theme from 'styles/theme';
import { addProtocol, getCategoryColor } from 'common/helpers';
import richTextOptions from 'common/rich-text-options';
import PageSection from 'atomic-design/atoms/page-section';
import { HeaderText } from './work/styles';
import { Diagonal, NavigationTitle, ChipContainer } from './work/styles';
import { validateCaseStudy } from '../common/validations';
import MissingDataPage from '../components/atomic-design/atoms/missing-data-page';
import RichTextWrapper from 'atomic-design/organisms/rich-text-wrapper';

export const query = graphql`
  query ($slug: String!) {
    contentfulCaseStudy(slug: { eq: $slug }) {
      title
      subtitle
      slug
      description
      LimitedAbstract
      technologies {
        name
        url
        image {
          file {
            url
          }
          description
          contentful_id
        }
        imageSvg {
          file {
            url
          }
          description
          contentful_id
        }
      }
      thumbnail {
        contentful_id
        file {
          url
        }
      }
      hero {
        title
        contentful_id
      }
      body {
        json
      }
      metaTagImage {
        contentful_id
        file {
          url
        }
      }
    }
  }
`;

const Work = ({ pageContext, data, location }) => {
  const { previous, next } = pageContext;
  const work = validateCaseStudy(data.contentfulCaseStudy);
  return (
    <Layout bodyType="xWide" path={location.pathname}>
      {work.error ? (
        <MissingDataPage error={work.error}></MissingDataPage>
      ) : (
        <>
          <MetaTags
            title={`${work.title} - NaNLABS`}
            description={work.description}
            charSet="utf-8"
            image={addProtocol(work.metaTagImage.file.url)}
            type="article"
            viewport="width=device-width, initial-scale=1"
          />
          <PageSection containerSize="s">
            <HeaderText>
              <ChipContainer>
                <h4>{work.LimitedAbstract}</h4>
              </ChipContainer>
              <h1> {work.title} </h1>
              <p> {work.subtitle}</p>
            </HeaderText>
            <Bubbles elements={work.technologies} />
          </PageSection>
          <HeroImage>
            <Image
              alt={work.hero.title}
              contentful_id={work.hero.contentful_id}
              fromContentful
              desktopHeight="408px"
              tabletHeight="316px"
              mobileHeight="255px"
              borderRadius={theme.newTheme.border.radius.medium}
            />
          </HeroImage>
          <PageSection containerSize="s">
            <RichTextWrapper
              color={getCategoryColor('ourWork')}
              json={{
                data: work.body.json.data,
                content: work.body.json.content,
                nodeType: work.body.json.nodeType,
              }}
              options={richTextOptions}
            />
          </PageSection>
        </>
      )}

      <PageSection
        background={`${theme.newTheme.color.primary.light}20`}
        margin={`${theme.newTheme.spacing(25)} 0 0 0`}
        padding={`0 0 ${theme.newTheme.spacing(25)} 0`}
        mobilePadding={`0 0 ${theme.newTheme.spacing(1)} 0`}
      >
        <Diagonal />
        <NavigationTitle>More case studies</NavigationTitle>
        <NavigationCards
          previous={previous.node}
          previousTo={`/work/${previous.node.slug}`}
          next={next ? next.node : undefined}
          nextTo={`/work/${next.node.slug}`}
          from="Work"
          withArrow
        />
      </PageSection>
    </Layout>
  );
};

Work.propTypes = {
  work: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    metaTagImage: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    }),
    hero: PropTypes.shape({
      title: PropTypes.string.isRequired,
      contentful_id: PropTypes.string.isRequired,
    }),
    body: PropTypes.shape({
      json: PropTypes.instanceOf(Object),
    }),
  }),
};

Work.defaultProps = {
  work: null,
};
export default Work;
