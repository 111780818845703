import React from 'react';

import PageSection from '../page-section';
import { HeaderText } from './styles';

const MissingDataPage = error => {
  const errorDetails = JSON.parse(JSON.stringify(error)).error.details;
  const errorOriginal = JSON.parse(JSON.stringify(error)).error._original;

  const getDescription = err =>
    err.context?.value
      ? `${err.context?.key}: ${err.context?.value}.`
      : `${err.context?.key} is invalid.`;

  return (
      <PageSection containerSize="s">
        <HeaderText>
          <h1> Error displaying page information </h1>
          <p>
            {errorDetails.map(err => (
              <>
                <b>{err.message}.</b>
                <br />
                {getDescription(err)}
                <hr />
              </>
            ))}
          </p>
          <p>
            <p>More information:</p>
            <br />
            {Object.entries(errorOriginal).map(([k, v]) => {
              return (
                <>
                  {' '}
                  <p>
                    <b>{k}:</b> {JSON.stringify(v)}
                  </p>
                  <br />
                </>
              );
            })}
          </p>
        </HeaderText>
      </PageSection>
  );
};

export default MissingDataPage;
