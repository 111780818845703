import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 0;
  padding-bottom: 52px;

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    letter-spacing: -0.01em;
    color: ${({ theme }) => theme.colors.primary};
    margin: 0;
    padding: 0 0 26px 0;

    b {
      font-weight: bold;
    }
  }

  h3,
  h4 {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 39px;
    letter-spacing: -0.01em;
    color: ${({ theme }) => theme.colors.primary};
    padding: 18px 0 0 0;

    b {
      font-weight: bold;
    }
  }

  p {
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
    letter-spacing: -0.01em;
    color: ${({ theme }) => theme.newTheme.color.grey.main};

    b {
      font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    }

  }

  ul {
    list-style-type: ' • ';
    li {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  a {
    text-decoration: underline solid ${({ theme }) => theme.colors.primary} 2px;
  }
`;
