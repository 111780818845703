import React from 'react';
import PropTypes from 'prop-types';

import { BubblesContainerStyled } from './styles';
import Bubble from './bubble';

const Bubbles = ({ elements }) => (
  <BubblesContainerStyled>
    {elements &&
      elements.map(element => (
        <Bubble
          key={element.url}
          url={element.url}
          image={element.imageSvg.file.url}
          imageDescription={element.image.description}
          name={element.name}
        />
      ))}
  </BubblesContainerStyled>
);

Bubbles.propTypes = {
  elements: PropTypes.string.isRequired,
};

export default Bubbles;
