import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: ${({ theme }) => theme.newTheme.spacing(15)};
  ${({ theme }) =>
    `margin: ${theme.newTheme.spacing(2)} ${theme.newTheme.spacing(5)} ${theme.newTheme.spacing(
      3
    )} 0`};

  h6 {
    margin: 0;
    padding-top: ${({ theme }) => theme.newTheme.spacing(2)};
    visibility: hidden;
    transition: ease-in-out 6s linear;
  }

  &:hover {
    h6 {
      visibility: inherit;
      transition: ease-in-out 6s linear;
    }
  }
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.newTheme.spacing(15)};
  height: ${({ theme }) => theme.newTheme.spacing(15)};

  background-color: ${({ theme }) => theme.newTheme.color.service.consultancy.background};
  border-radius: ${({ theme }) => theme.newTheme.border.radius.full};

  img {
    filter: invert(57%) sepia(44%) saturate(658%) hue-rotate(180deg) brightness(110%) contrast(101%);
    width: ${({ theme }) => theme.newTheme.spacing(9)};
    height: ${({ theme }) => theme.newTheme.spacing(9)};
  }
`;

export const BubblesContainerStyled = styled.div`
  width: 100%;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  padding-top: ${({ theme }) => theme.newTheme.spacing(8)};
`;
