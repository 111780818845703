import styled from 'styled-components';

export const NavigationTitle = styled.h3`
  font-size: ${({ theme }) => theme.newTheme.font.title3.size};
  line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  color: ${({ theme }) => theme.newTheme.color.black};
  margin: ${({ theme }) => `0 0 ${theme.newTheme.spacing(20)} 0`};
  padding: ${({ theme }) => `${theme.newTheme.spacing(20)} 0 0 0`};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
    display: none;
  }
`;

export const Diagonal = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  border-top: ${({ theme }) => `${theme.newTheme.spacing(20)} solid white`};
  border-left: 100vw solid transparent;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    border-top: ${({ theme }) => `${theme.newTheme.spacing(10)} solid white`};
  }
`;

export const ChipContainer = styled.div`
  h4 {
    margin: 0;
    font-size: ${({ theme }) => theme.newTheme.font.text1.size};
    line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.medium};
    color: ${({ theme }) => theme.newTheme.color.primary.main};
  }
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.newTheme.spacing(15)} 0`};
  width: 100%;

  h1 {
    font-size: ${({ theme }) => theme.newTheme.font.title1.size};
    line-height: ${({ theme }) => theme.newTheme.font.title1.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
    color: ${({ theme }) => theme.newTheme.color.black};
  }

  p {
    font-size: ${({ theme }) => theme.newTheme.font.title6.size};
    line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
    color: ${({ theme }) => theme.newTheme.color.grey.main};
    margin: 0;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    h1 {
      font-size: ${({ theme }) => theme.newTheme.font.title2.size};
      line-height: ${({ theme }) => theme.newTheme.font.title2.lineHeight};
      font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
    }
    p {
      font-size: ${({ theme }) => theme.newTheme.font.text1.size};
      line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
      font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    padding: ${({ theme }) => `${theme.newTheme.spacing(10)} 0`};
    h1 {
      font-size: ${({ theme }) => theme.newTheme.font.title4.size};
      line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
      font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
    }
  }
`;
